<template>
    <div>
        <Modal
            ref="modal"
            name="invoicing:expense:mark-as-paid-modal"
        >
            <div class="w-full flex flex-col justify-between items-center p-8">
                <h3 class="mb-8">{{ $t('invoicing.expense.edit_form.title') }}</h3>
                <div class="w-full uppercase mb-2">
                    {{ expense?.title }}
                </div>
                <div
                    class="p-4 mb-6 transition-all rounded-lg w-full"
                    :class="form.get('isPaid') ? 'bg-blue-100' : 'bg-neutral-100'"
                >
                    <FormCheckbox
                        :form="form"
                        input-name="isPaid"
                        :element-class-name="form.get('isPaid') ? 'mb-2' : ' w-full'"
                    />
                    <template v-if="form.get('isPaid')">
                        <FormTwoFields>
                            <template #field-1>
                                <FormSelect
                                    :form="form"
                                    input-name="paidBy"
                                    element-class-name=" "
                                    with-null-option
                                >
                                    <option
                                        v-for="paidBy in ['cash', 'debit', 'credit', 'other']"
                                        :key="paidBy"
                                        :value="paidBy"
                                    >
                                        {{ $t(`invoicing.expense_payment_type.${paidBy}`) }}
                                    </option>
                                </FormSelect>
                            </template>
                            <template #field-2>
                                <FormInput
                                    :form="form"
                                    input-name="paidByDetails"
                                    element-class-name=" "
                                />
                            </template>
                        </FormTwoFields>
                    </template>
                </div>
                <div class="flex gap-2 p-4 w-full justify-center">
                    <LoadingButton
                        :class-name="$theme('button.style.gray')"
                        @clicked="useEvent('invoicing:expense:mark-as-paid-modal:close')"
                    >
                        {{ $t('actions.cancel') }}
                    </LoadingButton>
                    <LoadingButton
                        :class-name="$theme('button.style.default')"
                        @clicked="markAsPaid"
                    >
                        <span>{{ $t('actions.save') }}</span>
                    </LoadingButton>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
    import { ModelType } from '~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~/app/factories/EndpointFactory';
    import Expense from '~/app/invoicing/models/Expense';
    import ExpenseSchema from '~~/app/invoicing/schemas/ExpenseSchema';

    const expense = ref<Expense | null>(null);

    const form = useForm<Fields>(
        {
            isPaid: true,
            paidBy: '',
            paidByDetails: '',
        },
        (field: string, value: any) => {
            //
        }
    );

    const endpoint = EndpointFactory.make(ModelType.EXPENSES);

    const markAsPaid = async () => {
        const schema = new ExpenseSchema({
            attributes: form.dataExcept(['contact', 'worksite', 'paidBy', 'paidByDetails']),
        });

        schema.attributes.isPaid = form.get('isPaid');
        schema.attributes.paidBy = form.get('paidBy');
        schema.attributes.paidByDetails = form.get('paidByDetails');
        schema.id = expense.value?.getId();

        await endpoint.update(schema);

        expense.value.attributes.isPaid = form.get('isPaid');
        useToasteoSuccess();
        useEvent('invoicing:expense:updated', expense.value);
        useEvent('invoicing:expense:mark-as-paid-modal:close');
    };

    useModal('invoicing:expense:mark-as-paid-modal', (payload: Expense) => {
        // TBD if we want to do soemthing else
        expense.value = payload;
        if (expense.value) {
            form.fillWithModel(expense.value);
        }
    });
</script>
