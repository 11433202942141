<script setup lang="ts">
    import Invoice from '~~/app/invoicing/models/Invoice';

    interface PayloadInterface {
        invoice: Invoice;
    }
    const invoice = reactiveModel<Invoice>(null);
    const loading = ref(false);

    useModal('invoicing:invoice:select-contact', (payload: PayloadInterface) => {
        invoice.value = payload.invoice;
    });

    const edit = () => {
        close();
        invoice.value?.edit();
    };

    const close = () => {
        useEvent('invoicing:invoice:select-contact:close');
    };
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice:select-contact"
    >
        <ModalLoader v-if="loading" />
        <template v-else>
            <ModalLottie
                :title="$t('invoicing.invoice.select-contact.error.title')"
                json="https://shared-assets.trustup.io/lottie/error.json"
            >
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close"
                    >
                        {{ $t('actions.close') }}
                    </LoadingButton>
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.default')"
                        prevent-default
                        @clicked="edit"
                    >
                        {{ $t('actions.edit') }}
                    </LoadingButton>
                </div>
            </ModalLottie>
        </template>
    </Modal>
</template>
