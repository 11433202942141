<template>
    <div>
        <Modal
            ref="modal"
            name="common:files:export-instruction-modal"
        >
            <div class="w-full h-[200px] flex flex-col justify-between">
                <p class="p-4 mt-14 text-center font-bold">
                    {{ $t('common.files.modal.export.instructions') }}
                </p>
                <div class="flex justify-end p-4 w-full">
                    <LoadingButton
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="useEvent('common:files:export-instruction-modal:close')"
                    >
                        {{ $t('actions.close') }}
                    </LoadingButton>
                    <LoadingButton
                        :class-name="$theme('button.style.default')"
                        @clicked="exportProducts"
                    >
                        <i class="mr-2 fa-regular fa-file-export"></i>
                        <span>{{ $t('actions.export') }}</span>
                    </LoadingButton>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script lang="ts" setup>
    import { ModelType } from '~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~/app/factories/EndpointFactory';

    const { t } = useTranslation();

    const endpoint = EndpointFactory.make(ModelType.IMPORTED_FILE);

    const exportProducts = async () => {
        const response = await endpoint.exportBase({ documentType: 'products' });
        useEvent('common:files:export-instruction-modal:close');
        window.open(response.data?.url, '_blank')?.focus();
    };

    useModal('common:files:export-instruction-modal', (payload: any) => {
        // TBD if we want to do soemthing else
    });
</script>
