<script setup lang="ts">
    import Task from '@/app/task/models/Task';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import TaskSchema from '~~/app/task/schemas/TaskSchema';
    import User from '~~/app/auth/models/User';
    import { useEvent } from '@/composables/useEventBus';
    import TaskEndpoint from '~~/app/task/endpoints/TaskEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import Worksite from '~~/app/worksite/models/Worksite';
    import WorksitePhase from '~~/app/worksite/models/WorksitePhase';
    import Lead from '@/app/crm/models/Lead';
    import Contact from '@/app/contact/models/Contact';

    interface Fields {
        taskType: 'worksite' | 'lead' | 'contact';
        title: string;
        dueDate: string | null;
        users: Array<User> | null;
        contact: Contact | null;
        lead: Lead | null;
        worksite: Worksite | null;
        worksitePhase: WorksitePhase | null;
    }
    const form = useForm<Fields>({
        taskType: 'worksite',
        title: '',
        dueDate: null,
        users: null,
        contact: null,
        lead: null,
        worksite: null,
        worksitePhase: null,
    });

    const { modalName, model } = useModelFormModal<Task, Fields>(
        Task,
        (payload?: FormModalPayload<Task, Fields>) => fillForm(payload),
        () => form.reset()
    );

    const fillForm = (payload?: FormModalPayload<Task, Fields>) => {
        form.reset();

        if (payload?.model) {
            form.fillWithModel(payload.model);
            form.set('users', payload.model.users?.toArray() || null);
        }

        if (payload?.fields) {
            form.fill(payload.fields);
        }

        if (form.get('contact')) {
            form.set('taskType', 'contact');
        }
        if (form.get('lead')) {
            form.set('taskType', 'lead');
        }
    };

    const submit = async () => {
        const schema = TaskSchema.make({
            attributes: form.dataExcept(['taskType', 'contact', 'lead', 'users', 'worksite', 'worksitePhase']),
        });

        schema.addToOneRelationship('contact', ModelType.CONTACTS, null);
        schema.addToOneRelationship('lead', ModelType.LEADS, null);
        schema.addToOneRelationship('worksite', ModelType.WORKSITES, null);
        schema.addToOneRelationship('worksitePhase', ModelType.WORKSITE_PHASES, null);

        if (form.get('taskType') == 'contact' && form.get('contact')) {
            schema.addToOneRelationship('contact', ModelType.CONTACTS, form.get('contact').getId());
        }
        if (form.get('taskType') == 'lead' && form.get('lead')) {
            schema.addToOneRelationship('lead', ModelType.LEADS, form.get('lead').getId());
        }
        if (form.get('taskType') == 'worksite' && form.get('worksite')) {
            schema.addToOneRelationship('worksite', ModelType.WORKSITES, form.get('worksite').getId());
        }
        if (form.get('taskType') == 'worksite' && form.get('worksitePhase')) {
            schema.addToOneRelationship('worksitePhase', ModelType.WORKSITE_PHASES, form.get('worksitePhase').getId());
        }
        if (form.get('users')) {
            form.get('users').forEach((user: User) => {
                schema.addToManyRelationship('users', ModelType.USERS, user.getId());
            });
        } else {
            schema.addEmptyToManyRelationship('users', ModelType.USERS);
        }
        if (model.value) {
            schema.id = model.value?.getId();
        }

        const endpoint: TaskEndpoint = EndpointFactory.make(ModelType.TASKS) as TaskEndpoint;
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal :model="Task">
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('task.task.edit_form.title') : $t('task.task.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <div class="flex items-stretch mb-6 border border-gray-200 divide-x divide-gray-200">
                    <div
                        v-for="taskType in useGetTaskTypes()"
                        :key="taskType.key"
                        :data-tooltip="taskType.title"
                        class="relative flex items-center justify-center p-4 text-base transition-all duration-300 cursor-pointer grow"
                        :class="[form.get('taskType') === taskType.key ? '' : 'text-[#f5f5f5] hover:text-black']"
                        :style="`
                        background-color: ${form.get('taskType') === taskType.key ? taskType.color : '#f5f5f5'};
                        color: ${form.get('taskType') === taskType.key ? 'white' : '#374151'}
                    `"
                        @click.prevent="form.set('taskType', taskType.key)"
                    >
                        <div
                            v-show="form.get('taskType') === taskType.key"
                            class="absolute inset-0 z-10 flex items-center justify-center pointers-event-none"
                        >
                            <i :class="`fa-solid fa-${taskType.icon} text-white`"></i>
                        </div>
                        <div class="w-[20px] flex justify-center">
                            <i
                                v-if="form.get('taskType') !== taskType.key"
                                :class="`fa-regular fa-${taskType.icon}`"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="form.get('taskType') == 'worksite'"
                    class="flex space-x-2"
                >
                    <div class="flex-1">
                        <FormWorksiteSelect
                            :form="form"
                            input-name="worksite"
                        />
                    </div>
                    <div
                        v-if="form.get('worksite')"
                        class="flex-1"
                    >
                        <FormWorksitePhaseSelect
                            :form="form"
                            input-name="worksitePhase"
                            :worksite="form.get('worksite')"
                            @changed="form.set('worksitePhase', null)"
                        />
                    </div>
                </div>
                <template v-if="form.get('taskType') == 'contact'">
                    <FormContactSelect
                        :form="form"
                        input-name="contact"
                    />
                </template>
                <template v-if="form.get('taskType') == 'lead'">
                    <FormLeadSelect
                        :form="form"
                        input-name="lead"
                    />
                </template>
                <FormInput
                    :form="form"
                    input-name="title"
                    autofocus
                />
                <FormDate
                    :form="form"
                    input-name="dueDate"
                />
                <FormUserSelect
                    :form="form"
                    input-name="users"
                    multiple
                    :close-on-select="false"
                    form-key="users"
                />
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
