<script setup lang="ts">
    import VueDatePicker from '@vuepic/vue-datepicker';
    import moment, { type MomentInstance } from '~~/utils/moment';
    import WorksitePhase from '@/app/worksite/models/WorksitePhase';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import WorksitePhaseSchema from '~~/app/worksite/schemas/WorksitePhaseSchema';
    import { useEvent } from '@/composables/useEventBus';
    import WorksitePhaseEndpoint from '~~/app/worksite/endpoints/WorksitePhaseEndpoint';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import Worksite from '~~/app/worksite/models/Worksite';
    import '@vuepic/vue-datepicker/dist/main.css';

    interface Fields {
        title: string;
        worksite: Worksite | null;
        startsAt: string;
        endsAt: string | null;
    }
    const shouldSelectWorksite = ref(true);
    const form = useForm<Fields>({
        title: '',
        worksite: null,
        startsAt: moment().startOf('day').toDateTimeString(),
        endsAt: moment().startOf('day').add(14, 'days').toDateTimeString(),
    });
    const dateRange = computed({
        get() {
            return [moment(form.get('startsAt')), moment(form.get('endsAt'))];
        },
        set(value: Array<MomentInstance>) {
            form.set('startsAt', moment(value[0]).toDateTimeString());
            form.set('endsAt', moment(value[1]).toDateTimeString());
        },
    });

    const disabled = computed(() => {
        if (!form.get('title')) {
            return true;
        }
        if (shouldSelectWorksite.value && !form.get('worksite')) {
            return true;
        }

        return false;
    });

    const { modalName, model } = useModelFormModal<WorksitePhase, Fields>(
        WorksitePhase,
        (payload?: FormModalPayload<WorksitePhase, Fields>) => fillForm(payload),
        () => {
            form.reset();
            shouldSelectWorksite.value = true;
        }
    );

    const fillForm = (payload?: FormModalPayload<WorksitePhase, Fields>) => {
        if (!payload) {
            return;
        }

        if (payload?.fields) {
            form.fill(payload.fields);
        }

        if (payload?.fields?.worksite || payload?.model) {
            shouldSelectWorksite.value = false;
        }

        if (payload?.model) {
            form.fill({
                title: payload?.model.title,
                worksite: payload?.model.worksite,
                startsAt: payload?.model.startsAt?.toDateTimeString() || '',
                endsAt: payload?.model.endsAt?.toDateTimeString() || '',
            });
        }
    };

    const submit = async () => {
        const schema = WorksitePhaseSchema.make({ attributes: form.dataExcept(['worksite']) });
        if (form.get('worksite')) {
            schema.addToOneRelationship('worksite', ModelType.WORKSITES, form.get('worksite').getId());
        }
        if (model.value) {
            schema.id = model.value?.getId();
        }

        const endpoint: WorksitePhaseEndpoint = EndpointFactory.make(ModelType.WORKSITE_PHASES) as WorksitePhaseEndpoint;
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="WorksitePhase"
        @before-close="form.reset()"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text')">
                {{ model ? $t('worksite.worksite_phase.edit_form.title') : $t('worksite.worksite_phase.create_form.title') }}
            </h2>
        </div>
        <form @submit.prevent="submit">
            <div :class="$theme('modal.padding')">
                <FormWorksiteSelect
                    v-if="shouldSelectWorksite"
                    :form="form"
                    input-name="worksite"
                />
                <FormInput
                    :form="form"
                    input-name="title"
                    autofocus
                />
                <FormDate
                    :form="form"
                    input-name="startsAt"
                    alignment="bottom-full"
                    position="left-0"
                    :label="$t('form.labels.dates')"
                >
                    <template #input="{ toggle }">
                        <div
                            :class="$theme('form.input', 'w-full flex items-center space-x-4 cursor-pointer')"
                            @click.prevent="toggle()"
                        >
                            <i class="fa-regular fa-calendar"></i>
                            <span> {{ moment(form.get('startsAt')).toShortDayDateString() }} - {{ moment(form.get('endsAt')).toShortDayDateString() }} </span>
                        </div>
                    </template>
                    <template #datepicker="{ hide }">
                        <VueDatePicker
                            v-model="dateRange"
                            inline
                            auto-apply
                            range
                            :locale="useGetCurrentMomentLocale()"
                            :partial-range="false"
                            :enable-time-picker="false"
                            multi-calendars
                            :multi-static="false"
                            @update:model-value="hide"
                        />
                    </template>
                </FormDate>
                <!-- <div :class="$theme('form.containers.element')">
                    <VueDatePicker v-model="dateRange" auto-apply range :partial-range="false" :enable-time-picker="false">
                        <template #trigger>
                            <label :class="$theme('form.label')">
                                {{ $t('form.labels.dates') }}
                            </label>
                            <div :class="$theme('form.input', 'w-full flex items-center space-x-4')">
                                <i class="fa-regular fa-calendar"></i>
                                <span>
                                    {{ moment(form.get('startsAt')).toShortDayDateString() }} - {{ moment(form.get('endsAt')).toShortDayDateString() }}
                                </span>
                            </div>
                        </template>
                    </VueDatePicker>
                </div> -->
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :class-name="disabled ? $theme('button.style.disabled') : $theme('button.style.default')"
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        type="submit"
                        :disabled="disabled"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
        </form>
    </ModelFormModal>
</template>
