<script setup lang="ts">
    import Tenant from '~/app/auth/models/Tenant';

    interface PayloadInterface {
        tenant: Tenant;
        callback: () => void;
    }

    const form = useForm<{
        consent: boolean;
    }>({
        consent: false,
    });

    const disabled = computed(() => !form.get('consent'));
    const callback = ref<() => void>();
    useModal(
        'settings:tenants:subscription',
        (payload: PayloadInterface) => {
            callback.value = payload?.callback;
        },
        () => {
            form.reset();
        }
    );

    const close = () => {
        useEvent('settings:tenants:subscription:close');
    };

    const submit = () => {
        if (form.get('consent')) {
            callback.value?.();
        }
        close();
    };
</script>

<template>
    <Modal
        ref="modal"
        name="settings:tenants:subscription"
    >
        <div>
            <div class="p-4 pt-9 md:px-14">
                <div class="">
                    <h2 class="mb-8 font-inter text-[20px] font-light text-center leading-[100%] tracking-[-2%]">
                        {{ $t('settings.tenants.subscription.title') }}
                    </h2>

                    <p class="font-inter text-[16px] font-normal leading-[142%] mb-3">{{ $t('settings.tenants.subscription.info') }}</p>
                    <InfoBox>
                        <p class="text-[12px]">{{ $t('settings.tenants.subscription.subtext') }}</p>
                    </InfoBox>
                </div>
            </div>
            <form @submit.prevent="submit">
                <div class="p-4 md:px-14 md:pb-8">
                    <div class="flex w-full mb-8">
                        <FormCheckbox
                            :form="form"
                            without-label
                            :input-name="'consent'"
                        ></FormCheckbox>
                        <label
                            for="consent"
                            class="font-inter text-[14px] font-normal leading-[142%] ml-2"
                        >
                            {{ $t('form.labels.consent') }}
                        </label>
                    </div>

                    <div :class="'space-y-4'">
                        <LoadingButton
                            :class-name="disabled ? $theme('button.style.custom_disabled') : $theme('button.style.default')"
                            :class="disabled ? 'cursor-not-allowed ' : ''"
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            :disabled="disabled"
                            type="submit"
                        >
                            {{ $t('actions.plan_update') }}
                        </LoadingButton>
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="close"
                        >
                            {{ $t('actions.cancel') }}
                        </LoadingButton>
                    </div>
                </div>
            </form>
        </div>
    </Modal>
</template>
