<script setup lang="ts">
    import { WorksiteProfitabilityFields } from './FormModal.vue';
    import Invoice from '~/app/invoicing/models/Invoice';
    import Form from '~/classes/Form';
    import InvoiceCollection from '~/app/invoicing/collections/InvoiceCollection';
    import { ModelType } from '~/app/base/schemas/BaseSchema';

    const props = defineProps({
  form: null
});

    const { loading, collection, load, filters } = useCollectionLoader<Invoice, InvoiceCollection>(
        ModelType.INVOICES,
        [
            {
                key: 'archived',
                defaultValue: '0',
            },

            {
                key: 'page',
                defaultValue: '1',
            },
        ],
        () => {
            const params = {
                include: 'contact,worksite,user',
                filter: {
                    documentType: props.form.get('documentType'),
                    withoutWorksite: true,
                    statusNot: ['draft', 'canceled'],
                },
                page: { number: filters.get('page'), size: 10 },
            };

            return params;
        },
        (collection: InvoiceCollection) => {
            collection.setItemShouldBeInCollectionCallback((invoice: Invoice) => {
                return invoice.documentType === props.form.get('documentType');
            });
            return collection;
        }
    );

    const emit = defineEmits(["submit"]);

    const submit = (document: Invoice) => {
        props.form.set('invoice', document);
        props.form.set('title', document.number);
        props.form.set('amount', document.subtotal);
        props.form.set('status', document.status);
        props.form.set('documentType', document.documentType);

        emit('submit');
    };

    onMounted(async () => {
        await load();
    });
</script>

<template>
    <div class="mb-8">
        <Loader v-if="loading" />
        <div v-else>
            <div
                v-for="document in collection"
                :key="document.getInternalId()"
                class="p-3 mb-2 transition-all border border-gray-100 rounded-lg cursor-pointer hover:bg-neutral-50"
                @click="submit(document)"
            >
                <div class="flex items-center justify-between">
                    <!-- Left side: Title and Date -->
                    <div class="flex flex-col">
                        <div class="flex items-center space-x-2">
                            <span class="text-sm font-medium text-gray-900">{{ document.title ?? '-' }}</span>
                            <span class="text-sm font-medium text-gray-900">{{ document.number ?? '-' }}</span>
                        </div>
                        <span class="text-xs text-gray-500">{{ document.createdAt?.toShortDayDateString() }}</span>
                    </div>
                    <!-- Right side: Total -->
                    <div class="flex items-center">
                        <span class="text-sm font-semibold text-primary">
                            {{ $price(document?.total) }}
                        </span>
                        <i class="ml-2 text-xs text-gray-400 fa-regular fa-chevron-right"></i>
                    </div>
                </div>
            </div>

            <Pagination
                :pagination="collection?.getPagination()"
                @click="(page) => filters.set('page', page)"
            />
        </div>
    </div>
</template>
