<script setup lang="ts">
    import { NotificationContainer, useToasteo } from '@deegital/vue-trustup-io-toasteo';
    import { storeToRefs } from 'pinia';
    const toasteo = useToasteo();
    const translation = useTranslation();

    const { isLoading, loading, load } = useLoadingScreen(useRoute());
    const { tenant, authed, isImpersonating } = storeToRefs(useAuthStore());
    const { currentModule, modules, appsModules, extraModules } = useModules();
    const InvoicingLayoutSidebar = resolveComponent('InvoicingLayoutSidebar');
    const WorksiteLayoutSidebar = resolveComponent('WorksiteLayoutSidebar');
    const CrmLayoutSidebar = resolveComponent('CrmLayoutSidebar');
    const SettingsLayoutSidebar = resolveComponent('SettingsLayoutSidebar');
    const CurrentLayoutSidebar = computed(() => {
        switch (currentModule.value?.key) {
            case 'invoicing':
                return InvoicingLayoutSidebar;
            case 'worksite':
                return WorksiteLayoutSidebar;
            case 'crm':
                return CrmLayoutSidebar;
            case 'settings':
                return SettingsLayoutSidebar;
        }
    });

    const sidebarCollapsed = ref(false);
    const toggleSidebarCollapsed = () => {
        sidebarCollapsed.value = !sidebarCollapsed.value;
        localStorage.setItem('sidebarCollapsed', sidebarCollapsed.value.toString());
    };

    const testModeHeight = computed(() => (tenant.value?.isInTestMode ? '60px' : '0px'));

    const showIntercom = () => {
        window.Intercom('showNewMessage');
    };

    const locale = computed(() => translation.getCurrentLocale());

    const helpCenterLink = computed(() => {
        if (locale.value === 'be-fr') {
            return 'https://intercom.help/trustuppro/fr/';
        }
        if (locale.value === 'be-nl') {
            return 'https://intercom.help/trustuppro/fr/';
        }
        if (locale.value === 'fr-fr') {
            return 'https://intercom.help/trustuppro/fr/';
        } else {
            return 'https://intercom.help/trustuppro/fr/';
        }
    });
    const clientSupportLink = useClientSupportLink();

    onMounted(async () => {
        if (localStorage.getItem('sidebarCollapsed') === 'true') {
            sidebarCollapsed.value = true;
        }

        if (load) {
            await load();
        }

        if (authed.value && tenant.value?.shouldAcceptTermsOfService(authed.value)) {
            useEvent('common:terms-of-service:accept-modal:open', tenant.value.meta.termsOfService.id);
        }
    });
</script>

<template>
    <div
        :key="translation.getCurrentLocale()"
        class="relative flex flex-col h-screen"
    >
        <transition
            leave-active-class="duration-300 ease-in"
            leave-from-class="opacity-100 blur-none"
            leave-to-class="opacity-0 blur-xl"
        >
            <LayoutLoadingScreen
                v-if="isLoading"
                :loading="loading"
            />
        </transition>
        <template v-if="!isLoading">
            <header class="">
                <div
                    v-if="tenant?.hasOverdueInvoices && ['admin', 'super-admin'].includes(authed?.role)"
                    class="w-full bg-[#ffeeb6] h-[40px] px-[20px] py-2 font-semibold text-black cursor-pointer"
                    @click="navigateTo({ name: 'settings-invoices' })"
                >
                    <div class="flex space-x-[10px] items-center text-center content-center justify-center">
                        <i
                            class="fa-duotone fa-solid fa-triangle-exclamation text-[16px]"
                            style="--fa-primary-color: #000000; --fa-secondary-color: #bac063"
                        ></i>
                        <h2 class="font-inter leading-4 space-x-1 text-[14px]">
                            <span>{{ $t('layout.header.overdue_invoices_warning') }} </span>
                            <span class="font-bold underline"> {{ $t('layout.header.overdue_invoices_warning_link') }} </span>
                            <span> <i class="ml-[8px] fa-regular fa-arrow-right text-[16px]"></i></span>
                        </h2>
                    </div>
                </div>
                <div class="bg-white dark:bg-neutral-900 flex items-stretch justify-between border-b border-gray-200 dark:border-gray-900 h-[64px] px-[20px] py-[10px]">
                    <div class="flex items-center space-x-4">
                        <NuxtLink
                            href="/"
                            class="h-full py-2"
                        >
                            <img
                                src="~/assets/logo.svg"
                                class="h-full"
                            />
                        </NuxtLink>
                        <!-- <TrustupProSwitch /> -->
                    </div>
                    <div class="flex items-center space-x-4">
                        <!-- <LayoutHeaderHelp /> -->
                        <LayoutHeaderSearch />
                        <LayoutHeaderNotifications />
                        <!-- <LayoutHeaderQuickActions /> -->
                        <LayoutHeaderAuthed />
                    </div>
                </div>
            </header>

            <div class="flex items-stretch flex-1">
                <aside class="flex-shrink-0 bg-primary-dark dark:bg-neutral-900 w-[60px] hidden md:flex z-10 flex-col">
                    <div class="flex flex-col flex-1 divide-y divide-[#455474]">
                        <LayoutSidebarModules
                            :modules="appsModules"
                            :current-module="currentModule"
                        />
                        <LayoutSidebarModules
                            :modules="extraModules"
                            :current-module="currentModule"
                        />
                    </div>
                    <div class="flex flex-col items-center px-2 py-4 space-y-2">
                        <!-- Legacy intercom custom submenu
                        <Submenu
                            ref="userSubmenu"
                            :container="$theme('submenu.container.withoutPosition', 'px-4 py-2')"
                            alignment="bottom-0"
                            position="left-full"
                            without-max-height
                            overlay
                        >
                            <template #default="{ toggle }">
                                <a
                                    href="#"
                                    class="relative flex items-center justify-center w-12 h-12 transition-all rounded-xl hover:bg-blue-gray-600"
                                    @click.prevent.stop="toggle()"
                                >
                                    <i class="text-xl text-white fa-light fa-question-circle"></i>
                                </a>
                            </template>
                            <template #submenu="{ hide }">
                                <div class="w-full min-w-[260px]">
                                    <div :class="$theme('submenu.items')">
                                        <a
                                            href="#"
                                            :class="$theme('submenu.item')"
                                            target="_blank"
                                            @click.prevent="
                                                showIntercom();
                                                hide();
                                            "
                                        >
                                            <i :class="$theme('submenu.item_icon', 'fa-regular fa-messages')" />
                                            <span>{{ $t('layout.header.help.chat') }}</span>
                                        </a>
                                        <a
                                            :href="helpCenterLink"
                                            :class="$theme('submenu.item')"
                                            target="_blank"
                                            @click="hide"
                                        >
                                            <i :class="$theme('submenu.item_icon', 'fa-regular fa-comment-question')" />
                                            <span>{{ $t('layout.header.help.help_center') }}</span>
                                        </a>
                                        <a
                                            :href="clientSupportLink"
                                            :class="$theme('submenu.item')"
                                            target="_blank"
                                            @click="hide"
                                        >
                                            <i :class="$theme('submenu.item_icon', 'fa-regular fa-user-headset')" />
                                            <span>{{ $t('layout.header.help.support') }}</span>
                                        </a>
                                    </div>
                                </div>
                            </template>
                        </Submenu> -->
                    </div>
                </aside>
                <div class="flex-col flex-1 h-content w-content">
                    <div
                        v-if="tenant && tenant.isInTestMode"
                        class="hidden md:block"
                    >
                        <PageTestModeAlert :tenant="tenant" />
                    </div>
                    <div class="flex items-stretch flex-1">
                        <aside
                            v-if="currentModule && CurrentLayoutSidebar"
                            class="relative group/module-sidebar"
                        >
                            <div>
                                <div
                                    class="absolute top-0 h-[80px] z-[99] right-0 items-center hidden lg:group-hover/module-sidebar:flex -mr-[5px]"
                                    @click.prevent="toggleSidebarCollapsed"
                                >
                                    <div
                                        class="h-[20px] w-[20px] bg-white border border-gray-100 flex-shrink-0 rounded-full flex items-center justify-center transition-all hover:bg-neutral-100 text-gray-600 hover:text-black cursor-pointer"
                                        :data-tooltip="sidebarCollapsed ? $t('actions.expand_sidebar') : $t('actions.collapse_sidebar')"
                                    >
                                        <i
                                            class="text-xs fa-solid"
                                            :class="sidebarCollapsed ? 'fa-chevron-right' : 'fa-chevron-left'"
                                        ></i>
                                    </div>
                                </div>
                                <div
                                    :class="[
                                        sidebarCollapsed ? $theme('layout.sidebar_collapsed') : $theme('layout.sidebar'),
                                        tenant?.isInTestMode ? 'md-h-inner-content-test-mode' : 'md-h-inner-content',
                                    ]"
                                >
                                    <div
                                        class="relative items-center justify-center hidden px-2 py-6 border-b border-gray-200 cursor-default md:flex dark:border-gray-900 group"
                                        :class="sidebarCollapsed ? 'justify-center' : 'lg:px-6 lg:justify-start space-x-3'"
                                    >
                                        <div
                                            class="rounded-full flex items-center justify-center h-[32px] w-[32px] flex-shrink-0"
                                            :style="`background-color: ${currentModule.color}`"
                                        >
                                            <i :class="`fa-solid fa-${currentModule.icon} text-white text-base`"></i>
                                        </div>
                                        <span
                                            v-if="!sidebarCollapsed"
                                            class="hidden text-xl lg:block"
                                            :style="`color: ${currentModule.color}`"
                                        >
                                            {{ $t(`modules.${currentModule.key}`) }}
                                        </span>
                                    </div>
                                    <div class="flex justify-center flex-1 md:justify-stretch">
                                        <component
                                            :is="CurrentLayoutSidebar"
                                            :collapsed="sidebarCollapsed"
                                        />
                                    </div>
                                    <div class="hidden p-2 mt-8 md:block">
                                        <div
                                            v-if="!sidebarCollapsed"
                                            v-can="'planning:time_activity:view_any'"
                                            class="hidden lg:block"
                                        >
                                            <PlanningLayoutSidebarCurrentTimeActivities />
                                        </div>
                                        <div class="justify-center hidden mt-2 md:flex">
                                            <SettingsActivateSubscriptionButton
                                                v-if="!tenant?.subscriptionPlanConfig.isCombo"
                                                :collapsed="sidebarCollapsed"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                        <main
                            class="flex-1 overflow-y-auto bg-white dark:bg-neutral-800 nice-scrollbar"
                            :class="[tenant?.isInTestMode ? 'h-inner-content-test-mode' : 'h-inner-content']"
                        >
                            <slot />
                        </main>
                    </div>
                </div>
            </div>
            <client-only>
                <NotificationContainer :toasteo="toasteo" />
                <LayoutModals />
            </client-only>
        </template>
    </div>
</template>

<style>
    .h-content {
        height: calc(100vh - 64px);
    }
    .w-content {
        width: calc(100vw);
    }

    .h-inner-content {
        height: calc(100vh - 64px);
    }
    .h-inner-content-test-mode {
        height: calc(100vh - 64px - 60px);
    }

    @media (min-width: 768px) {
        .w-content {
            width: calc(100vw - 60px);
        }
        .md-h-inner-content {
            height: calc(100vh - 64px);
        }
        .md-h-inner-content-test-mode {
            height: calc(100vh - 64px - 60px);
        }
    }
</style>
