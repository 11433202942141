import { BaseSchema, ModelType, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type WorksiteProfitabiltiyDocumentRelationships = {
    worksite: ToOneRelationship<ModelType.WORKSITES>;
    invoice: ToOneRelationship<ModelType.INVOICES>;
    pdf: ToOneRelationship<ModelType.PDFS>;
};

type WorksiteProfitabiltiyDocumentAttributes = {
    title: string;
    documentType: string;
    amount: number;
    status: string;
};

type WorksiteProfitabiltiyDocumentMeta = {};

export default class WorksiteProfitabiltiyDocumentSchema extends BaseSchema<WorksiteProfitabiltiyDocumentAttributes, WorksiteProfitabiltiyDocumentMeta, WorksiteProfitabiltiyDocumentRelationships> {
    protected type: ModelType = ModelType.WORKSITE_PROFITABILITY_DOCUMENTS;
}
