<script setup lang="ts">
    import Invoice from '@/app/invoicing/models/Invoice';
    import InvoicePaymentReminderEndpoint from '@/app/invoicing/endpoints/InvoicePaymentReminderEndpoint';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import InvoicingPaymentRemindersSettingCollection from '@/app/invoicing/collections/InvoicingPaymentRemindersSettingCollection';
    import InvoicingPaymentRemindersSetting from '@/app/invoicing/models/InvoicingPaymentRemindersSetting';
    import EndpointFactory from '~/app/factories/EndpointFactory';

    interface PayloadInterface {
        invoice: Invoice;
    }
    const error = ref(false);
    const success = ref<boolean>(false);
    const invoice = ref<Invoice | null>(null);
    const selectedPaymentReminderSetting = ref<InvoicingPaymentRemindersSetting | null>(null);
    const invoiceEndpoint = EndpointFactory.make(ModelType.INVOICES);

    const { loading, collection, load } = useCollectionLoader<InvoicingPaymentRemindersSetting, InvoicingPaymentRemindersSettingCollection>(ModelType.INVOICING_PAYMENT_REMINDERS_SETTINGS, [], () => {
        const group = invoice.value?.contact?.invoicePaymentReminderGroup;
        const filter = group
            ? {
                  invoicePaymentReminderGroup: {
                      id: [invoice.value?.contact?.invoicePaymentReminderGroup?.getId()],
                  },
              }
            : { defaultGroup: true };

        return {
            filter,
        };
    });

    const form = useForm<{
        subject: string;
        text: string;
        to: string;
        copy: boolean;
    }>({
        subject: '',
        text: '',
        to: '',
        copy: true,
    });

    const fetchInvoice = async (invoiceId: string) => {
        const response = await invoiceEndpoint.retrieve(invoiceId, { include: 'contact,contact.invoicePaymentReminderGroup' });
        if (response.error) {
            return;
        }

        invoice.value = response.data;
    };

    useModal(
        'invoicing:invoice:send-payment-reminder',
        async (payload: PayloadInterface) => {
            loading.value = true;
            await fetchInvoice(payload.invoice.getId());
            await load();
            const firstSetting = collection.value?.first();
            if (firstSetting) {
                selectedPaymentReminderSetting.value = firstSetting;
                fillForm();
            }
            form.set('to', payload.invoice.contact?.email);
        },
        () => {
            success.value = false;
            form.reset();
            error.value = false;
            invoice.value = null;
        }
    );

    const fillForm = () => {
        if (!selectedPaymentReminderSetting.value) {
            return;
        }

        form.set('subject', selectedPaymentReminderSetting.value.getTranslation('emailSubject'));
        form.set('text', selectedPaymentReminderSetting.value.getTranslation('emailText'));
    };

    const submit = async () => {
        form.load();
        const endpoint = new InvoicePaymentReminderEndpoint();
        const response = await endpoint.send({
            invoiceId: invoice.value!.getId(),
            subject: form.get('subject'),
            text: form.get('text'),
            to: form.get('to'),
            copy: form.get('copy'),
        });
        if (!response.ok) {
            form.stop();
            error.value = true;
        }
        success.value = true;
    };

    const closeModal = () => useEvent('invoicing:invoice:send-payment-reminder:close');
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice:send-payment-reminder"
        scrollable
    >
        <template v-if="loading">
            <div class="py-12">
                <div class="relative">
                    <Loader />
                </div>
            </div>
        </template>
        <template v-else>
            <template v-if="error">
                <ModalLottie
                    :title="$t('invoicing.invoice.send_modal.error.title')"
                    :subtitle="$t('invoicing.invoice.send_modal.error.text')"
                    json="https://shared-assets.trustup.io/lottie/error.json"
                >
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="closeModal"
                        >
                            {{ $t('actions.close') }}
                        </LoadingButton>
                    </div>
                </ModalLottie>
            </template>
            <template v-else>
                <template v-if="success">
                    <ModalLottie
                        :title="$t('invoicing.send_payment_reminder.success.title')"
                        :subtitle="$t('invoicing.send_payment_reminder.success.text', { email: form.get('to') })"
                    >
                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :class-name="$theme('button.style.cancel')"
                                prevent-default
                                @clicked="closeModal"
                                >{{ $t('actions.close') }}</LoadingButton
                            >
                        </div>
                    </ModalLottie>
                </template>
                <template v-else>
                    <div :class="$theme('modal.title.container')">
                        <h2 :class="$theme('modal.title.text')">{{ $t('actions.send_payment_reminder') }}</h2>
                    </div>
                    <form @submit.prevent="submit">
                        <div :class="$theme('modal.padding')">
                            <label :class="$theme('form.label')">
                                {{ $t('invoicing.send_payment_reminder.select_reminder_level') }}
                            </label>
                            <select
                                v-model="selectedPaymentReminderSetting"
                                :class="$theme('form.select_full_width', 'mb-6')"
                                @change="fillForm()"
                            >
                                <option
                                    v-for="paymentReminderSetting in collection?.get()"
                                    :key="paymentReminderSetting.getInternalId()"
                                    :value="paymentReminderSetting"
                                >
                                    {{ $t('misc.level_number', { level: paymentReminderSetting.level }) }}
                                </option>
                            </select>
                            <FormInput
                                :form="form"
                                input-name="subject"
                                :label="$t('form.labels.emailSubject')"
                            />
                            <FormEditor
                                :form="form"
                                input-name="text"
                                :label="$t('form.labels.emailText')"
                            />
                            <FormEmailInput
                                :form="form"
                                input-name="to"
                                :label="$t('form.labels.recipient')"
                            />
                            <FormCheckbox
                                :form="form"
                                input-name="copy"
                                :label="$t('form.labels.copy')"
                            />
                            <div :class="$theme('modal.footer.button_container')">
                                <LoadingButton
                                    :extra-class-name="$theme('modal.footer.button')"
                                    :class-name="$theme('button.style.cancel')"
                                    prevent-default
                                    @clicked="closeModal"
                                    >{{ $t('actions.cancel') }}
                                </LoadingButton>
                                <LoadingButton
                                    :extra-class-name="$theme('modal.footer.button')"
                                    :loading="form.isLoading"
                                    type="submit"
                                    >{{ $t('actions.send') }}</LoadingButton
                                >
                            </div>
                        </div>
                    </form>
                </template>
            </template>
        </template>
    </Modal>
</template>
