<script setup lang="ts">
    const form = useForm<{}>({});

    useModal('invoicing:invoice-form:edit-disable', () => {});

    const confirm = () => {
        useEvent('invoicing:invoice-form:edit-disable:close');
    };
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice-form:edit-disable"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text', 'px-12')">
                {{ $t('invoicing.invoice_form.edit_document_alert.title') }}
            </h2>
        </div>
        <div :class="$theme('modal.padding')">
            <div class="flex justify-center mb-8">
                <div class="max-w-[180px] h-[180px]">
                    <Lottie json="https://shared-assets.trustup.io/lottie/warning.json" />
                </div>
            </div>

            <div :class="$theme('modal.footer.button_container')">
                <LoadingButton
                    :extra-class-name="$theme('modal.footer.button')"
                    :class-name="$theme('button.style.cancel')"
                    prevent-default
                    @clicked="useEvent('invoicing:invoice-form:edit-disable:close')"
                >
                    {{ $t('actions.cancel') }}
                </LoadingButton>
                <LoadingButton
                    :extra-class-name="$theme('modal.footer.button')"
                    :class-name="$theme('button.style.default')"
                    :loading="form.isLoading"
                    @clicked="confirm"
                >
                    {{ $t('actions.understood') }}
                </LoadingButton>
            </div>
        </div>
    </Modal>
</template>
