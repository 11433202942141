<script setup lang="ts">
    import WorksiteProfitabilityDocument from '@/app/worksite/models/WorksiteProfitabilityDocument';

    const { model } = useModelModal<WorksiteProfitabilityDocument>(WorksiteProfitabilityDocument);
</script>

<template>
    <ModelModal
        :model="WorksiteProfitabilityDocument"
        modal-style="right_panel"
    >
        <div
            v-if="model"
            :class="$theme('modal.right_panel.content')"
        >
            <div :class="$theme('modal.right_panel.title.container', 'flex items-center justify-between')">
                <div>
                    <h2 :class="$theme('modal.right_panel.title.text')">
                        <span v-if="model.title">
                            {{ model.title }}
                        </span>
                        <span class="block text-sm">
                            {{ $t(`worksite_profitability_document_type.${model.documentType}`) }}
                        </span>
                    </h2>
                </div>
                <div
                    v-if="model.hasStatus"
                    class="mr-12"
                >
                    <WorksiteWorksiteProfitabilityDocumentStatusBadge
                        :key="model.getInternalId()"
                        :worksite-profitability-document="model"
                    />
                </div>
            </div>
            <div :class="$theme('modal.right_panel.section.container')">
                <InfoText :label="$t('form.labels.amount')">
                    {{ $price(model.amount) }}
                </InfoText>
                <InfoText :label="$t('form.labels.added_at')">
                    {{ model.createdAt.toShortDayDateTimeString() }}
                </InfoText>
                <InfoText
                    v-if="model.model"
                    :label="$t('form.labels.source')"
                >
                    <a
                        href="#"
                        class="hover:underline"
                        @click.prevent="model.openModel()"
                    >
                        {{ $t('actions.open') }}
                    </a>
                </InfoText>
            </div>
            <!-- // IFRAME WITH PDF -->
            <div
                v-if="model.expense?.pdf"
                class="p-4"
            >
                <iframe
                    :src="`${model.expense?.pdf.url}#navpanes=0`"
                    class="w-full h-full min-h-[800px] mb-4 border border-gray-200 rounded"
                ></iframe>
            </div>
            <div
                v-if="model?.pdf"
                class="p-4"
            >
                <iframe
                    :src="`${model?.pdf.url}#navpanes=0`"
                    class="w-full h-full min-h-[800px] mb-4 border border-gray-200 rounded"
                ></iframe>
            </div>
        </div>
    </ModelModal>
</template>
