<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ExpenseEndpoint from '~~/app/invoicing/endpoints/ExpenseEndpoint';
    import Expense from '~~/app/invoicing/models/Expense';

    const endpoint = EndpointFactory.make(ModelType.EXPENSES) as ExpenseEndpoint;
    const { contextMenu, onAction, onPromise } = useModelContextMenu();

    const destroy = async (model: Expense, payload: LoadingButtonClickedEventPayload) => {
        await payload.loadUntil(onPromise(endpoint.destroy(model.getId())));
    };
</script>

<template>
    <ModelContextMenu
        v-slot="slotProps"
        ref="contextMenu"
        :model-type="Expense"
        :menu-height="200"
    >
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => slotProps.model?.edit())"
        >
            <i
                class="fa-pencil"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.edit') }}</span>
        </a>
        <a
            v-if="slotProps.model?.worksite"
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="
                slotProps.model.worksite.open();
                onAction();
            "
        >
            <i
                class="fa-regular fa-hard-hat"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.open_worksite') }}</span>
        </a>
        <a
            href="#"
            :class="$theme('contextmenu.link')"
            @click.prevent="onAction(() => useEvent('invoicing:expense:mark-as-paid-modal:open', slotProps.model))"
        >
            <i
                class="fa-regular fa-square-check"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('model_events_mark_as_paid') }}</span>
        </a>
        <LoadingButton
            :class-name="$theme('contextmenu.link')"
            prevent-default
            @clicked="(payload) => destroy(slotProps.model, payload)"
        >
            <i
                class="fa-trash"
                :class="$theme('contextmenu.icon')"
            ></i>
            <span>{{ $t('actions.delete') }}</span>
        </LoadingButton>
    </ModelContextMenu>
</template>
