<script setup lang="ts">
    import ExistingProfitabilityDocument from './ExistingProfitabilityDocument.vue';
    import WorksiteProfitabilityDocument from '@/app/worksite/models/WorksiteProfitabilityDocument';
    import WorksiteProfitabilityDocumentSchema from '~~/app/worksite/schemas/WorksiteProfitabilityDocumentSchema';
    import { useEvent } from '@/composables/useEventBus';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import Worksite from '~~/app/worksite/models/Worksite';
    import Invoice from '~/app/invoicing/models/Invoice';
    import EndpointFactory from '~/app/factories/EndpointFactory';
    import useUploadPdf from '~/composables/useUploadPdf';
    import { InvoiceDocumentType } from '~/app/invoicing/enums/InvoiceDocumentType';

    const { uploading: uploadingPdf, pdf, upload: uploadPdf } = useUploadPdf();
    const inputFile = ref<HTMLInputElement | null>(null);

    export interface WorksiteProfitabilityFields {
        title?: string;
        documentType: string;
        worksite: Worksite | null;
        invoice: Invoice | null;
        amount?: number;
        status?: string;
    }
    const form = useForm<WorksiteProfitabilityFields>({
        title: '',
        documentType: 'expense',
        worksite: null,
        invoice: null,
        amount: 0,
        status: 'null',
    });

    const documentTypeState = ref<string>('expense');
    const modelState = ref<WorksiteProfitabilityDocument | null | undefined>();

    const { modalName, model } = useModelFormModal<WorksiteProfitabilityDocument, WorksiteProfitabilityFields>(
        WorksiteProfitabilityDocument,
        (payload?: FormModalPayload<WorksiteProfitabilityDocument, WorksiteProfitabilityFields>) => fillForm(payload),
        () => reset()
    );

    const fillForm = (payload?: FormModalPayload<WorksiteProfitabilityDocument, WorksiteProfitabilityFields>) => {
        if (!payload) {
            alert('You need to provide the worksite on which you are working...');
            return;
        }
        if (payload.fields?.worksite) {
            form.set('worksite', payload.fields.worksite);
        }
        if (payload.fields?.documentType) {
            documentTypeState.value = payload.fields.documentType;
            form.set('documentType', payload.fields.documentType);
        }

        if (payload.model) {
            modelState.value = payload.model;
            fillModel(payload.model);
        }
    };

    const fillModel = (model: WorksiteProfitabilityDocument) => {
        if (!model) return;
        form.fill({
            title: model.title,
            worksite: model.worksite,
            invoice: model.invoice,
            amount: model.amount,
            documentType: model.documentType,
        });
        if (model.pdf) {
            pdf.value = model.pdf;
        }
    };

    const handleFileUpload = async (event: Event) => {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (!file) return;

        await uploadPdf(file);
        inputFile.value!.value = '';
    };

    const isManualyAdded = ref(true);

    const toggleIsManualyAdded = (callback?: () => void) => {
        const newValue = !isManualyAdded.value;
        resetFields();
        isManualyAdded.value = newValue;

        // Only execute callback when going from existing to manual mode
        if (newValue && callback && modelState.value) {
            callback();
        }
    };

    const submit = async () => {
        const schema = WorksiteProfitabilityDocumentSchema.make({
            attributes: form.dataExcept(['worksite', 'invoice']),
        });

        if (form.get('worksite')) {
            schema.addToOneRelationship('worksite', ModelType.WORKSITES, form.get('worksite').getId());
        }
        if (form.get('invoice')) {
            schema.addToOneRelationship('invoice', ModelType.INVOICES, form.get('invoice').getId());
        }

        if (pdf.value) {
            schema.addToOneRelationship('pdf', ModelType.PDFS, pdf.value.getId());
        }
        if (model.value) {
            schema.id = model.value?.getId();
        }

        const endpoint = EndpointFactory.make(ModelType.WORKSITE_PROFITABILITY_DOCUMENTS);
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        close();
    };

    const disabled = computed(() => {
        const documentType = form.get('documentType');
        return documentType !== InvoiceDocumentType.QUOTE && documentType !== InvoiceDocumentType.INVOICE;
    });

    // This should not happen, but just in case
    // reseting some fields when switching between tabs to avoid conflict when sending payload
    const resetFields = () => {
        pdf.value = null;
        form.set('title', '');
        form.set('amount', 0);
        form.set('invoice', null);
        form.set('status', 'null');
    };

    const reset = () => {
        form.reset();
        pdf.value = null;
        modelState.value = null;
        isManualyAdded.value = true;
    };

    const close = () => {
        reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="WorksiteProfitabilityDocument"
        @before-close="form.reset()"
    >
        <div
            :class="$theme('modal.title.container')"
            class="space-y-4"
        >
            <h2 :class="$theme('modal.title.text')">
                {{ $t('worksite.worksite_form.add_profitability_modal.title') }}
            </h2>
            <p :class="$theme('modal.title.subtext')">
                {{ $t('form.worksite.worksite_profitability.add_profitability.info', { document: $t(`worksite_profitability_document_type.${form.get('documentType')}`) }) }}
            </p>
        </div>
        <form
            class="p-4"
            @submit.prevent="submit"
        >
            <div class="flex items-stretch w-full mb-4">
                <button
                    :disabled="disabled"
                    :class="[
                        disabled ? 'cursor-not-allowed' : '',
                        $theme('modal.title.text'),
                        !isManualyAdded ? 'bg-primary text-white hover:bg-primary-light ' : 'bg-white hover:bg-neutral-100 hover:text-black text-gray-60',
                    ]"
                    class="cursor-pointer w-full px-4 py-2 text-sm transition-all border rounded-l border-primary"
                    @click.prevent="() => toggleIsManualyAdded()"
                >
                    <i class="mr-2 fa-regular fa-layer-group" />

                    <span>
                        {{
                            $t('worksite.worksite_profitability_link_document', {
                                document: $t(`worksite_profitability_document_type.${form.get('documentType')}`),
                            })
                        }}</span
                    >
                </button>
                <button
                    :class="[$theme('modal.title.text'), isManualyAdded ? 'bg-primary text-white hover:bg-primary-light ' : 'bg-white hover:bg-neutral-100 hover:text-black text-gray-60']"
                    class="cursor-pointer w-full px-4 py-2 text-sm transition-all border border-l-0 rounded-r border-primary"
                    @click.prevent="
                        () =>
                            toggleIsManualyAdded(() => {
                                console.log('Callback executing with modelState:', modelState);
                                if (modelState) fillModel(modelState);
                            })
                    "
                >
                    <i class="mr-2 fa-regular fa-plus" />

                    <span> {{ model ? $t('worksite.worksite_profitability_document.edit_form.title') : $t('worksite.worksite_profitability_document.create_form.title') }}</span>
                </button>
            </div>

            <div
                v-if="isManualyAdded"
                :class="$theme('modal.padding')"
            >
                <FormTwoFields>
                    <template #field-1>
                        <FormSelect
                            :key="form.get('documentType')"
                            :form="form"
                            input-name="documentType"
                            :with-null-option="false"
                            :options="[
                                {
                                    value: 'expense',
                                    text: $t('worksite_profitability_document_type.expense'),
                                },
                                {
                                    value: 'quote',
                                    text: $t('worksite_profitability_document_type.quote'),
                                },
                                {
                                    value: 'invoice',
                                    text: $t('worksite_profitability_document_type.invoice'),
                                },
                                {
                                    value: 'labor',
                                    text: $t('worksite_profitability_document_type.labor'),
                                },
                                {
                                    value: 'subcontracting',
                                    text: $t('worksite_profitability_document_type.subcontracting'),
                                },
                                {
                                    value: 'other',
                                    text: $t('worksite_profitability_document_type.other'),
                                },
                            ]"
                        />
                    </template>
                    <template #field-2>
                        <FormWorksiteProfitabilityStatusSelect
                            :form="form"
                            input-name="status"
                        />
                    </template>
                </FormTwoFields>
                <FormTwoFields>
                    <template #field-1>
                        <FormInput
                            :form="form"
                            input-name="title"
                            autofocus
                        />
                    </template>
                    <template #field-2>
                        <FormPrice
                            :form="form"
                            input-name="amount"
                        />
                    </template>
                </FormTwoFields>
                <div class="mb-4">
                    <div v-if="!uploadingPdf">
                        <label class="text-sm text-center transition-all cursor-pointer text-neutral-600 hover:text-black hover:underline">
                            {{ pdf ? pdf.title : $t('invoicing.expense.form.add_pdf_file') }}
                            <input
                                ref="inputFile"
                                type="file"
                                class="hidden"
                                accept="application/pdf"
                                @change="handleFileUpload"
                            />
                        </label>
                    </div>
                    <div v-else><i class="fa-duotone fa-spinner fa-spin-pulse"></i></div>
                </div>
                <div :class="$theme('modal.footer.button_container')">
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :class-name="$theme('button.style.cancel')"
                        prevent-default
                        @clicked="close()"
                        >{{ $t('actions.cancel') }}</LoadingButton
                    >
                    <LoadingButton
                        :extra-class-name="$theme('modal.footer.button')"
                        :loading="form.isLoading"
                        :disabled="uploadingPdf"
                        type="submit"
                        >{{ $t('actions.save') }}</LoadingButton
                    >
                </div>
            </div>
            <ExistingProfitabilityDocument
                v-else
                class="p-4"
                :form="form"
                @submit="submit"
            />
        </form>
    </ModelFormModal>
</template>
